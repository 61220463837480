const messages: any = {
    modal_lbl_device: {
        it: 'Dispositivo',
        en: 'Device',
    },
    modal_lbl_zone: {
        it: 'Zona',
        en: 'Zone',
    },
    modal_lbl_pages: {
        it: 'Pagine',
        en: 'Pages',
    },
    modal_lbl_network: {
        it: 'Rete',
        en: 'Network',
    },
    modal_lbl_duration: {
        it: 'Durata',
        en: 'Duration',
    },
    modal_lbl_co2e: {
        it: 'CO2e',
        en: 'CO2e',
    },
    modal_lbl_your_web_impact: {
        it: 'Il tuo Web Impact',
        en: 'Your Web Impact',
    },
    modal_lbl_session_data: {
        it: 'Dati della sessione',
        en: 'Session Data',
    },
    modal_lbl_download: {
        it: 'Scarica',
        en: 'Download',
    },
    modal_lbl_close: {
        it: 'Chiudi',
        en: 'Close',
    },

};

export class DedaloAITranslator {

    private language: string;

    constructor(language: string = 'it') {
        this.language = language;
    }

    public translate(key: string): string {
        let translation = key;
        if (messages[key]) {
            translation = messages[key][this.language] || key;
        }
        return translation;
    }


}