//---------------------------------------------------------------------------------------------
// Badge HTML IDs
// ---------------------------------------------------------------------------------------------
export const BADGE_CONTAINER_ID: string = 'ddlai-badge';
export const BADGE_LOADER_ID: string = 'ddlai-badge-loader';
export const BADGE_ICON_ID: string = 'ddlai-badge-icon';
export const BADGE_TEXT_ID: string = 'ddlai-badge-text-content';
export const BADGE_LOGO_ID: string = 'ddlai-badge-logo';
export const BADGE_BODY_ID: string = 'ddlai-badge-body';
export const BADGE_OPEN_MODAL_BTN_ID: string = 'ddlai-badge-open-modal-btn';



// ---------------------------------------------------------------------------------------------
// Modal HTML IDs
// ---------------------------------------------------------------------------------------------

export const MODAL_OVERLAY_ID: string = 'ddlai-modal-overlay';
export const MODAL_CLOSE_BTN_ID: string = 'ddlai-modal-close-btn';
export const MODAL_TITLE_ID: string = 'ddlai-modal-title';
export const MODAL_INFO_DATA_HEADER_ID: string = 'ddlai-modal-info-data-header';
export const MODAL_AVATAR_ID: string = 'ddlai-modal-session-badge-avatar';
export const MODAL_MESSAGE_ID: string = 'ddlai-modal-session-badge-message';
// ---------------------------------------------------------------------------------------------
// Powered By
// ---------------------------------------------------------------------------------------------

export const POWERED_BY_NAME: string = 'DedaloAI';
export const POWERED_BY_URL: string = 'https://dedalo.ai';


// ---------------------------------------------------------------------------------------------
// Local Storage Key
// ---------------------------------------------------------------------------------------------

export const LOCAL_STORAGE_KEY: string = '__ddlai__';


// ---------------------------------------------------------------------------------------------
// API Endpoints
// ---------------------------------------------------------------------------------------------
export const API_BASE_URL: string = 'https://demo-platform.d-carbon.ai/api/webdomains/';
export const API_VERSION: string = '';
export const API_ENDPOINT_VERIFY: string = 'verify';
export const API_ENDPOINT_SESSION_CHECK: string = 'session/check/';
export const API_ENDPOINT_SESSION_OPEN: string = 'session/open/';
export const API_ENDPOINT_SESSION_LOG: string = 'session/log/';
export const API_ENDPOINT_SESSION_STATS: string = 'session/stats/';
