import { DedaloAIClient, DedaloAIClientOptions } from "../types/dedaloClient";
import { DebugService } from "./debug";
import { DedaloWebDmAPIService } from './api';
import { BadgeElement } from '../components/BadgeElement';
import { ModalElement } from '../components/ModalElement';
import { DedaloSessionLogger } from './sessionLogger';
import { DedaloAITranslator } from './translators';
import {
    bytesToString,
    durationToString,
    gCO2eToString
} from '../utils/utils';
import { 
    API_BASE_URL,
    API_VERSION,
} from '../constants';

export class DedaloAiClientService {
    private client: DedaloAIClient;
    private api: DedaloWebDmAPIService;
    private debug: DebugService;
    private badge: BadgeElement | null;
    private modal: ModalElement | null;
    private sessionLogger: DedaloSessionLogger;
    private options: DedaloAIClientOptions;
    private translator: DedaloAITranslator;


    constructor(projectId: string, apiKey: string, options?: DedaloAIClientOptions) {
        this.client = {
            project_id: projectId,
            api_key: apiKey
        };
        this.api = new DedaloWebDmAPIService({
            baseURL: API_BASE_URL,
            apiVersion: API_VERSION,
            apiKey: this.client.api_key
        });
        this.badge = null;
        this.modal = null;
        this.sessionLogger = new DedaloSessionLogger(this.api, this.client.project_id);
        this.options = options || {};
        this.debug = new DebugService();
        this.translator = new DedaloAITranslator('it');
    }

    private async verify(): Promise<boolean> {
        // try {
        //     const response: any = await this.api.post('verify', {
        //         project_id: this.client.project_id
        //     });
        //     return response.success === true;
        // } catch (e) {
        //     return false;
        // }
        return true;
    }

    private initBadge(): void {
        // console.log('this.options.badgeOptions', this.options.badgeOptions);
        if(this.options.badgeOptions) {
            this.badge = new BadgeElement(this.options.badgeOptions);
        } else {
            this.badge = new BadgeElement();
        }
    }

    private initModal(): void {
        this.modal = new ModalElement();
    }

    async init(): Promise<void> {
        // const verified = await this.verify();
        // if (!verified) {
        //     throw new Error('Failed to verify client (project_id or api_key is invalid)');
        // }

        // this.debug.init();
        // this.debug.log('DedaloAI Client initialized', this.client);
        this.initBadge();
        if(!this.badge) {
            throw new Error('BadgeElement not initialized');
        }

        this.initModal();
        if(!this.modal) {
            throw new Error('ModalElement not initialized');
        }

        this.badge.show();
        this.badge.showLoader();
        
        this.badge.setText('🔍 Stiamo calcolando il tuo impatto digitale...');
        // this.badge.setText('Opening session...');
        await this.sessionLogger.checkAndOpen();
        // this.badge.setText('Session opened');
        const pageLogResult = await this.sessionLogger.log();
        this.badge.hideLoader();
        // this.badge.setText('Session logged');
        this.badge.setText(pageLogResult.message);
        const sessionData = await this.sessionLogger.getSessionStats();
        this.modal.setTitle(this.translator.translate('modal_lbl_your_web_impact'));
        // this.modal.setInfoDataHeader(this.translator.translate('modal_lbl_session_data'));
        this.modal.setData('device',this.translator.translate('modal_lbl_device'), sessionData.device_vitals.model);
        this.modal.setData('zone', this.translator.translate('modal_lbl_zone'), 'Unknown');
        this.modal.setData('pages', this.translator.translate('modal_lbl_pages'), sessionData.page_visits);
        this.modal.setData('network', this.translator.translate('modal_lbl_network'), bytesToString(sessionData.total_size));
        this.modal.setData('duration', this.translator.translate('modal_lbl_duration'), durationToString(sessionData.session.started_at));
        // auto-update duration
        setInterval(() => {
            this.modal?.setData('duration', this.translator.translate('modal_lbl_duration'), durationToString(sessionData.session.started_at));
        }, 1000);
        this.modal.setData('co2e', 'CO2e', gCO2eToString(sessionData.total_emissions));
        this.modal.setMessage(sessionData.message);

        this.badge.showModalBtn();
        // this.badge.vibrate(500);


    }
     



}