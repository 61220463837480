import './assets/scss/style.scss';
import { DedaloAiClientService } from './services/dedaloAiClient';


(function (window: any) {
    if(typeof window === 'undefined') {
        console.error('DedaloAI Badge: window object not found');
        return ;
    }

    if(window.DedaloAI) {
        console.error('DedaloAI Badge: DedaloAI object already exists');
        return ;
    }

    const currnetScript = document.currentScript;
    let projectId = '';
    let apiKey = '';
    let options = {};

    projectId = currnetScript?.getAttribute('data-projectid') || '';
    apiKey = currnetScript?.getAttribute('data-apikey') || '';
    const optionsJson = currnetScript?.getAttribute('data-options') || '';
    console.log('optionsJson', optionsJson);
    if(optionsJson) {
        try {
            options = JSON.parse(optionsJson);
        } catch (e) {
            console.error(e);
            console.error('DedaloAI Badge: Failed to parse options JSON');
        }
    }


    const client = new DedaloAiClientService(projectId, apiKey, options);
    window.DedaloAI = client;
    client.init();
    
}(window));