export class DedaloWebDmAPIService {
    private baseURL: string;
    private apiVersion: string;
    private apiKey: string;


    constructor(options: { baseURL: string, apiVersion: string, apiKey: string }) {
        this.baseURL = options.baseURL;
        this.apiVersion = options.apiVersion;
        this.apiKey = options.apiKey;

        if (!this.baseURL) {
            throw new Error('baseURL is required');
        }

        
        if (!this.apiKey) {
            throw new Error('apiKey is required');
        }
    }

    async get<T>(path: string): Promise<{ status: number, data: any }> {
        const response = await fetch(`${this.baseURL}/${path}`, {
            headers: {
                // 'x-api-key': this.apiKey
            }
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch ${path}`);
        }

        return {
            status: response.status,
            data: await response.json()
        }
    }

    async post<T>(path: string, body: any): Promise<{ status: number, data: any }> {
        const response = await fetch(`${this.baseURL}/${path}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'x-api-key': this.apiKey
            },
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            throw new Error(`Failed to fetch ${path}`);
        }

        return {
            status: response.status,
            data: await response.json()
        }
    }
}