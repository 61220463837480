
export function bytesToString(bytes: number): string  {
    if(bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export function gCO2eToString(gCO2e: number): string {
    if(gCO2e === 0) return '0 gCO2e';
    const k = 1000;
    const sizes = ['gCO2e', 'kgCO2e', 'tCO2e'];
    const i = Math.floor(Math.log(gCO2e) / Math.log(k));
    if (i >= sizes.length) {
        return parseFloat((gCO2e / Math.pow(k, i)).toFixed(4)) + ' ' + sizes[sizes.length - 1];
    } else if (i < 0) {
        return parseFloat((gCO2e / Math.pow(k, 0)).toFixed(4)) + ' ' + sizes[0];
    }
    return parseFloat((gCO2e / Math.pow(k, i)).toFixed(4)) + ' ' + sizes[i];
}

// Given a timezoned date string return dd:HH:mm:ss as duration
export function durationToString(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const dd = days < 10 ? '0' + days : days;
    const HH = hours % 24 < 10 ? '0' + hours % 24 : hours % 24;
    const mm = minutes % 60 < 10 ? '0' + minutes % 60 : minutes % 60;
    const ss = seconds % 60 < 10 ? '0' + seconds % 60 : seconds % 60;
    return `${dd}:${HH}:${mm}:${ss}`;
}