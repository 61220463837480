import { DEDALOAI_BADGE_TEMPLATE } from '../templates/badge-template';
import { DEDALOAI_BADGE_FLAT_TEMPLATE } from '../templates/badge-flat-template';
import { BadgeElementOptions } from '../types/badge-element';
import {
    BADGE_CONTAINER_ID,
    BADGE_LOADER_ID,
    BADGE_ICON_ID,
    BADGE_TEXT_ID,
    BADGE_LOGO_ID,
    BADGE_BODY_ID,
    BADGE_OPEN_MODAL_BTN_ID,
} from '../constants';


/**
 * BadgeElement class
 * 
 * @class BadgeElement
 * @classdesc BadgeElement class to handle the badge element
 * @exports BadgeElement
 * @constructor
 * @public
 * 
 */
export class BadgeElement {

    badgeContainer: HTMLElement | null;
    badgeLoader: HTMLElement | null;
    badgeIcon: HTMLElement | null;
    badgeText: HTMLElement | null;
    badgeLogo: HTMLElement | null;
    badgeBody: HTMLElement | null;
    badgeOpenModalBtn: HTMLElement | null;
    options: BadgeElementOptions |  null;
    collapsed: boolean;
    drag_isDraggable: boolean;
    drag_isDragging: boolean;
    drag_offsetX: number;
    drag_offsetY: number;
    target: HTMLElement | null;

    constructor(options?: BadgeElementOptions) {
        const ddlbdg = document.createElement('div');
        ddlbdg.innerHTML = DEDALOAI_BADGE_TEMPLATE;
        
        this.options = options || null;
        this.target = null;

        if (this.options?.flat) {
            this.options.flat = this.options.target_id ? true : false;
        }
        if(this.options?.flat && this.options?.target_id) {
            this.target = document.getElementById(this.options?.target_id);
            if (!this.target) {
                throw new Error('BadgeElement: Could not find target');
            }
            ddlbdg.innerHTML = DEDALOAI_BADGE_FLAT_TEMPLATE;
            this.target.innerHTML = ddlbdg.innerHTML;
        } else {
            document.body.insertBefore(ddlbdg, document.body.firstChild);
        }

        
        this.badgeContainer = document.getElementById(BADGE_CONTAINER_ID);
        this.badgeLoader = document.getElementById(BADGE_LOADER_ID);
        this.badgeIcon = document.getElementById(BADGE_ICON_ID);
        this.badgeText = document.getElementById(BADGE_TEXT_ID);
        this.badgeLogo = document.getElementById(BADGE_LOGO_ID);
        this.badgeBody = document.getElementById(BADGE_BODY_ID);
        this.badgeOpenModalBtn = document.getElementById(BADGE_OPEN_MODAL_BTN_ID);
        
                
        if (this.options?.flat && this.options?.target_id) {
            this.badgeContainer?.classList.add('ddl-flat');
            this.badgeContainer?.classList.add('ddl-visible');
            this.badgeContainer?.classList.add('ddl-expanded');
        }
        if (this.options?.theme) {
            this.badgeContainer?.classList.add(`ddlai-theme-${this.options.theme}`);
        }

        this.collapsed = true;
        if (
            !this.badgeContainer ||
            !this.badgeLoader ||
            // !this.badgeIcon ||
            !this.badgeText ||
            !this.badgeLogo ||
            !this.badgeBody ||
            !this.badgeOpenModalBtn
        ) {
            throw new Error('BadgeElement: Could not find all elements');
        }


        if (this.options?.position && !this.options?.flat) {
            this.setPoistion(this.options.position);
        }
        this.drag_isDraggable = (!this.options?.flat && this.options?.draggable) ? this.options.draggable : false;
        this.drag_isDragging = false;
        this.drag_offsetX = 0;
        this.drag_offsetY = 0;
        this.initDrag(this.drag_isDraggable);
        
        if(!this.options?.flat) {
            this.badgeLogo.addEventListener('click', () => this.toggleCollapse());
        }
        this.badgeOpenModalBtn.addEventListener('click', () => {
            const modal: HTMLElement | null= document.getElementById('ddlai-modal-overlay');
            if (!modal) {
                throw new Error('BadgeElement: Could not find modal');
            }
            modal.classList.remove('ddl-hidden');
        });
    
    }
    vibrate(duration: number) {
        if(this.options?.flat) {
            return;
        }

        if (!this.badgeContainer) {
            throw new Error('BadgeElement: Could not find container');
        }
        this.badgeContainer.classList.add('ddl-vibrating');
        setTimeout(() => {
            this.badgeContainer?.classList.remove('ddl-vibrating');
        }, duration);
    }
    initDrag(isDraggable: boolean =  true ) {
        const draggableElement = this.badgeContainer;
        if (!draggableElement) {
            throw new Error('BadgeElement: Could not find container');
        }
        if(isDraggable) {
            draggableElement.addEventListener('mousedown', (e) => {
                this.drag_isDragging = true;
                this.drag_offsetX = e.clientX - draggableElement.getBoundingClientRect().left;
                this.drag_offsetY = e.clientY - draggableElement.getBoundingClientRect().top;
                draggableElement.style.cursor = 'grabbing';
            });
            
            document.addEventListener('mousemove', (e) => {
                if (this.drag_isDragging) {
                    // prevent dragging outside the window
                    if (e.clientX - this.drag_offsetX < 0) {
                        return;
                    }
                    if (e.clientY - this.drag_offsetY < 0) {
                        return;
                    }
                    if (e.clientX - this.drag_offsetX > window.innerWidth - draggableElement.offsetWidth) {
                        return;
                    }
                    if (e.clientY - this.drag_offsetY > window.innerHeight - draggableElement.offsetHeight) {
                        return;
                    }
                    draggableElement.style.left = `${e.clientX - this.drag_offsetX}px`;
                    draggableElement.style.top = `${e.clientY - this.drag_offsetY}px`;
                }
            });
            
            document.addEventListener('mouseup', () => {
                this.drag_isDragging = false;
                draggableElement.style.cursor = 'grab';
            });
        }
    }

    setPoistion(position: 'tl' | 'tr' | 'bl' | 'br') {

        if(this.options?.flat) {
            return;
        }

        if (!this.badgeContainer) {
            throw new Error('BadgeElement: Could not find container');
        }
        if(position==="br") {
            return;
        }
        this.badgeContainer.classList.add(`ddl-p-${position}`);
    }

    show() {
        if(this.options?.flat) {
            return;
        }
        this.badgeContainer?.classList.add('ddl-visible');
    }

    expand() {
        this.badgeContainer?.classList.add('ddl-expanded');
        this.badgeBody?.classList.add('expanded');
    }

    collapse() {
        this.badgeContainer?.classList.remove('ddl-expanded');
        this.badgeBody?.classList.remove('expanded');
    }

    hide() {
        if(this.options?.flat) {
            return;
        }
        this.badgeContainer?.classList.remove('ddl-visible');
    }

    toggleCollapse() {
        this.collapsed = !this.collapsed;
        if (this.collapsed) {
            this.collapse();
        } else {
            this.expand();
        }
    }
    showLoader() {
        if (!this.badgeLoader || !this.badgeLogo) {
            throw new Error('BadgeElement: Could not find loader');
        }
        this.badgeLoader.style.display = 'block';
        this.badgeLogo.style.display = 'none';
    }

    hideLoader() {
        if (!this.badgeLoader || !this.badgeLogo) {
            throw new Error('BadgeElement: Could not find loader');
        }
        this.badgeLoader.style.display = 'none';
        this.badgeLogo.style.display = 'block';
    }

    // setIcon(icon) {
    //     this.badgeIcon.innerHTML = `<i class="${ICONS[icon]}"></i>`;
    // }

    setText(text: string) {
        if (!this.badgeText) {
            throw new Error('BadgeElement: Could not find text');
        }
        this.badgeText.innerHTML = text;
    }

    showModalBtn() {
        if (!this.badgeOpenModalBtn) {
            throw new Error('BadgeElement: Could not find modal button');
        }
        this.badgeOpenModalBtn.classList.add('ddl-visible');
    }
    hideModalBtn() {
        if (!this.badgeOpenModalBtn) {
            throw new Error('BadgeElement: Could not find modal button');
        }
        this.badgeOpenModalBtn.classList.remove('ddl-visible');
    }
}

export default BadgeElement;