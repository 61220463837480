import { HardwareVitals, DeviceInfo } from "../types/device";
import UAParser from 'ua-parser-js';

export class DedaloDeviceAnalyzerService {

    private device: DeviceInfo | null;
    private hardware: HardwareVitals | null;

    constructor() {
        this.device = null;
        this.hardware = null;
    }

    getDevice(): DeviceInfo | null {
        return this.device;
    }

    getHardware(): HardwareVitals | null {
        return this.hardware;
    }

    analyze(): void {
        this.device = this.analyzeDevice();
        this.hardware = this.analyzeHardware();
    }

    private analyzeDevice(): DeviceInfo {
        const ua = navigator.userAgent;
        const parser = new UAParser(ua);
        return {
            ua: ua,
            browser: parser.getBrowser(),
            engine: parser.getEngine(),
            os: parser.getOS(),
            device: parser.getDevice(),
            cpu: parser.getCPU()
        };
    }

    private analyzeHardware(): HardwareVitals {
        return {
            // deviceMemory: navigator.deviceMemory,
            deviceMemory: undefined,
            hardwareConcurrency: navigator.hardwareConcurrency,
            maxTouchPoints: navigator.maxTouchPoints,
            screen: {
                availHeight: screen.availHeight,
                availWidth: screen.availWidth,
                colorDepth: screen.colorDepth,
                height: screen.height,
                width: screen.width
            },
            userAgent: navigator.userAgent,
            vendor: undefined,
            vendorSub: undefined
        };
    }

  
}