import {
    MODAL_OVERLAY_ID,
    MODAL_TITLE_ID,
    MODAL_INFO_DATA_HEADER_ID,
    MODAL_AVATAR_ID,
    MODAL_MESSAGE_ID,
    MODAL_CLOSE_BTN_ID,
    POWERED_BY_NAME,
    POWERED_BY_URL,
} from '../constants';
import alarm from '../assets/imgs/alarm.svg';
import map_pin from '../assets/imgs/map-pin.svg';
import globe from '../assets/imgs/globe.svg';
import device from '../assets/imgs/device.svg';
import network from '../assets/imgs/network.svg';
import leaf from '../assets/imgs/leaf_orange.svg';

export const DEDALO_AI_MODAL_TEMPLATE = `
<!-- DedaloAI Modal  START -->
<div id="${MODAL_OVERLAY_ID}" class="ddl-hidden">
    <div id="ddlai-modal">
        <button id="${MODAL_CLOSE_BTN_ID}">&times;</button>
        <div id="ddlai-modal-content">
            <div id="ddlai-modal-header">
                <h2 id="${MODAL_TITLE_ID}"></h2>
            </div>
            <div id="ddlai-modal-body">
                <div id="ddlai-modal-session-badge">
                    <div id="${MODAL_AVATAR_ID}">
                        AI
                    </div>
                    <div id="${MODAL_MESSAGE_ID}">
                        
                    </div>
                </div>
                <div id="ddlai-modal-session-info">
                    <!-- <div id="ddlai-modal-session-info-actions">
                        <button 
                            id="ddlai-modal-session-info-actions-download"
                            class="ddlai-modal-action-btn"
                        >
                            Download
                        </button>
                    </div> -->
                    <div id="ddlai-modal-session-info-data">
                        <div id="${MODAL_INFO_DATA_HEADER_ID}">
                            
                        </div>
                        <div id="ddlai-modal-session-info-data-cards">
                            <div class="ddlai-modal-session-info-data-card">
                                <img src="${device}" alt="Device" />
                                <div>
                                    <p class="ddlai-modal-session-info-data-card-title"></p>
                                    <p class="ddlai-modal-session-info-data-card-text" data-target="device"></p>
                                </div>
                            </div>
                            <div class="ddlai-modal-session-info-data-card">
                                <img src="${map_pin}" alt="Zone" />
                                <div>
                                    <p class="ddlai-modal-session-info-data-card-title"></p>
                                    <p class="ddlai-modal-session-info-data-card-text" data-target="zone"></p>
                                </div>
                            </div>
                            <div class="ddlai-modal-session-info-data-card">
                                <img src="${globe}" alt="Pages" />
                                <div>
                                    <p class="ddlai-modal-session-info-data-card-title"></p>
                                    <p class="ddlai-modal-session-info-data-card-text" data-target="pages"></p>
                                </div>
                            </div>
                            <div class="ddlai-modal-session-info-data-card">
                                <img src="${network}" alt="Traffic" />
                                <div>
                                    <p class="ddlai-modal-session-info-data-card-title"></p>
                                    <p class="ddlai-modal-session-info-data-card-text" data-target="network"></p>
                                </div>
                            </div>
                            <div class="ddlai-modal-session-info-data-card">
                                <img src="${alarm}" alt="Duration" />
                                <div>
                                    <p class="ddlai-modal-session-info-data-card-title"></p>
                                    <p class="ddlai-modal-session-info-data-card-text" data-target="duration"></p>
                                </div>
                            </div>
                            <div class="ddlai-modal-session-info-data-card highlighted">
                                <img src="${leaf}" alt="co2e" />
                                <div>
                                    <p class="ddlai-modal-session-info-data-card-title"></p>
                                    <p class="ddlai-modal-session-info-data-card-text" data-target="co2e"></p>
                                </div>
                            </div>
                        </div>
                        <div id="ddlai-modal-session-info-data-equivalence">
                        </div>
                        <div id="ddlai-modal-session-info-summary-cards">
                        </div>
                    </div>
                </div>
            </div>
            <div id="ddlai-modal-footer">
                Powered by <a href="${POWERED_BY_URL}" target="_blank">${POWERED_BY_NAME}</a>
            </div>
        </div>
    </div>
</div>
<!-- DedaloAI Modal END -->
`;  