import { DebugMessage } from "../types/debug";


export class DebugService {

    private debugContainer: HTMLElement | null;
    private debugEnabled: boolean ;

    constructor() {
        this.debugContainer = document.getElementById('ddlai-debug-container');
        this.debugEnabled = this.debugContainer !== null;
    }

    public init( ) : void {
        return ;
    }

    public log(message: string, data?: any) : void {
        if (!this.debugEnabled) {
            return;
        }
        let consoleMessage = message;
        if (data) {
            consoleMessage += ': ' + JSON.stringify(data);
        }
        console.log(consoleMessage);
        this.sendMessage({ message, data, severity: 'info' });
    }

    public warn(message: string, data?: any): void {
        if (!this.debugEnabled) {
            return;
        }
        let consoleMessage = message;
        if (data) {
            consoleMessage += ': ' + JSON.stringify(data);
        }
        console.warn(consoleMessage);
        this.sendMessage({ message, data, severity: 'warn' });
    }

    public error(message: string, data?: any): void {
        if (!this.debugEnabled) {
            return;
        }
        let consoleMessage = message;
        if (data) {
            consoleMessage += ': ' + JSON.stringify(data);
        }
        console.error(consoleMessage);
        this.sendMessage({ message, data, severity: 'error' });
    }

    private sendMessage(message: DebugMessage): void {
        if (this.debugContainer) {
            let formattedMessage = `<div class="ddlai-debug-message ddlai-debug-message--${message.severity}">${message.message}</div>`;
            if (message.data) {
                formattedMessage += `<pre>${JSON.stringify(message.data, null, 2)}</pre>`;
            }
            this.debugContainer?.insertAdjacentHTML('beforeend', formattedMessage);
        }
    }

}