import { DedaloWebDmAPIService } from "./api";
import { DedaloWebSession, DedaloSessionStats } from "../types/session";
import {
    DedaloDeviceAnalyzerService
} from './deviceAnalyzer';
import {
    DedaloPageAnalyzerService
} from './pageAnalyzer';
import {
    API_ENDPOINT_SESSION_CHECK,
    API_ENDPOINT_SESSION_OPEN,
    API_ENDPOINT_SESSION_LOG,
    API_ENDPOINT_SESSION_STATS
} from '../constants';
import {
    LOCAL_STORAGE_KEY
} from '../constants';

export class DedaloSessionLogger {

    private api: DedaloWebDmAPIService;
    private project_id: string;
    public session: DedaloWebSession;
    private deviceAnalyzer: DedaloDeviceAnalyzerService;
    private pageAnalyzer: DedaloPageAnalyzerService;

    constructor(api: DedaloWebDmAPIService, project_id: string) {
        this.api = api;
        this.session = {
            session_uuid: '',
            session_start: 0,
            session_end: 0,
            isOpen: false
        };
        this.deviceAnalyzer = new DedaloDeviceAnalyzerService();
        this.pageAnalyzer = new DedaloPageAnalyzerService();
        this.project_id = project_id;
        this.loadSession();
    }
    
    private loadSession() {
        const storedSession = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedSession) {
            this.session = JSON.parse(storedSession);
        }
    }

    private saveSession() {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.session));
    }


    /**
     * 
     * Check if the session is open
     * 
     * @returns 
     */
    async check(): Promise<boolean> {
        // if(!this.session.isOpen) {
        //     return false;
        // }
        try {
            const response: any = await this.api.post(API_ENDPOINT_SESSION_CHECK, {
                session_uuid: this.session.session_uuid
            });
            return response.status === 200;
        } catch {
            return false;
        }
    }

    async open(): Promise<void> {
        this.deviceAnalyzer.analyze();
        const deviceVitals = this.deviceAnalyzer.getDevice();
        const deviceData = {
            ua_string: deviceVitals?.ua,
            model: deviceVitals?.device.model ? deviceVitals.device.model : "Unknown",
            type: deviceVitals?.device.type ? deviceVitals.device.type : "Desktop",
            browser_name: deviceVitals?.browser.name,
            browser_version: deviceVitals?.browser.version,
            browser_major_version: deviceVitals?.browser.major,
            os_name: deviceVitals?.os.name,
            os_version: deviceVitals?.os.version,
            cpu_architecture: deviceVitals?.cpu.architecture
        }
        // console.log("Device Data: ", deviceData);
        const response : any = await this.api.post(API_ENDPOINT_SESSION_OPEN, {
            device_vitals: deviceData,
            website: this.project_id,
        });
        if ( response && response.status === 201) {
            this.session = {
                session_uuid: response.data.uuid,
                session_start: response.data.started_at,
                session_end: response.data.expire,
                isOpen: true
            };
            this.saveSession();
        }

    }

    async checkAndOpen() {
        if (await this.check()) {
            return;
        }
        await this.open();
    }

    async log() : Promise<any> {
        // await this.api.post('session/log', {});
        await this.pageAnalyzer.analyzePage();
        const pageData = this.pageAnalyzer.getPageData();
        // console.log("Session: ", this.session);
        const response: any = await this.api.post(API_ENDPOINT_SESSION_LOG, {
            session_uuid: this.session.session_uuid,
            log: {
                assets: [],
                url: pageData.url,
                path_name: pageData.pathname,
                page_size_bytes: pageData.pageSize,
            }
        });
        if (!response || response.status !== 200) {
            throw new Error('Error logging session');
        }

        return {
            estimated_emissions: response.data.estimated_emissions,
            message: response.data.message
        }
    }

    async getSessionStats(): Promise<DedaloSessionStats> {
        const response: any = await this.api.post(API_ENDPOINT_SESSION_STATS, {
            session_uuid: this.session.session_uuid
        });
        if (!response || response.status !== 200) {
            throw new Error('Error logging session');
        }
        return response.data;
    }


}