import { data } from '../../node_modules/autoprefixer/lib/autoprefixer';
import {
    MODAL_OVERLAY_ID,
    MODAL_CLOSE_BTN_ID,
    MODAL_TITLE_ID,
    MODAL_INFO_DATA_HEADER_ID,
    MODAL_MESSAGE_ID,
} from '../constants';

import { DEDALO_AI_MODAL_TEMPLATE } from "../templates/modal-template";

const DATA_IDS = [
    'device',
    'zone',
    'pages',
    'network',
    'duration',
    'co2e',
]

export class ModalElement {
    
    private modalOverlay: HTMLElement | null;
    private modalCloseBtn: HTMLElement | null;
    private modalTitle: HTMLElement | null;
    private modalInfoDataHeader: HTMLElement | null;
    private modalMessageContainerId: HTMLElement | null;
    private dataElements: { label: Element, data: Element }[];

    constructor() {
        const ddlmodal = document.createElement('div');
        ddlmodal.innerHTML = DEDALO_AI_MODAL_TEMPLATE;
        document.body.insertBefore(ddlmodal, document.body.firstChild);

        this.modalOverlay = document.getElementById(MODAL_OVERLAY_ID);
        this.modalCloseBtn = document.getElementById(MODAL_CLOSE_BTN_ID);
        this.modalTitle = document.getElementById(MODAL_TITLE_ID);
        this.modalInfoDataHeader = document.getElementById(MODAL_INFO_DATA_HEADER_ID);
        this.modalMessageContainerId = document.getElementById(MODAL_MESSAGE_ID);
        this.dataElements = [];
        DATA_IDS.forEach((dataId) => {
            this.dataElements.push(this.getInfoElement(dataId));
        });
        
        this.init();
    }

    private init() {
        this.modalTitle!.innerText = '';
        this.modalInfoDataHeader!.innerText = '';
        this.setElementLabel('device', 'Device');
        this.setElementLabel('zone', 'Zone');
        this.setElementLabel('pages', 'Pages');
        this.setElementLabel('network', 'Network');
        this.setElementLabel('duration', 'Duration');
        this.setElementLabel('co2e', 'CO2e');
        this.modalCloseBtn!.addEventListener('click', () => {
            this.close();
        });
        
    }

    public setTitle(title: string) {
        this.modalTitle!.innerText = title;
    }

    public setInfoDataHeader(header: string) {
        this.modalInfoDataHeader!.innerText = header;
    }

    public open() {
        this.modalOverlay!.classList.remove('ddl-hidden');
    }

    public close() {
        this.modalOverlay!.classList.add('ddl-hidden');
    }

    private setElementLabel(key: string, value: string) {
        const element = this.dataElements.find((element) => element.data.getAttribute('data-target') === key);
        if (element) {
            (element.label as HTMLElement).innerText = value;
        }
    }

    private setElementData(key: string, value: string) {
        const element = this.dataElements.find((element) => element.data.getAttribute('data-target') === key);
        if (element) {
            (element.data as HTMLElement).innerText = value;
        }
    }
    
    private getInfoElement(infoId: string): { label: Element, data: Element } {
        const dataElement = document.querySelector(`#${MODAL_OVERLAY_ID} [data-target="${infoId}"]`);
        if (!dataElement) {
            throw new Error(`Element with data-target=${infoId} not found`);
        }
        const labelElement = dataElement.previousElementSibling;
        if (!labelElement) {
            throw new Error(`Element with data-target=${infoId} not found`);
        }
        return { label: labelElement, data: dataElement };
    }

    public setData(key: string, label: string, value: string) {
        this.setElementLabel(key, label);
        this.setElementData(key, value);
    }

    public setMessage(message: string) {
        this.modalMessageContainerId!.innerText = message;
    }
}